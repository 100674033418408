import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import UserPool from "../components/userPool.js"
import Modal from '@mui/material/Modal';
import { Loader } from 'rsuite';
import { useEffect } from 'react';
import Countdown from 'react-countdown';

import {CognitoUserAttribute, CognitoUser,AuthenticationDetails} from "amazon-cognito-identity-js"
function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'AGC © '}
      
        Seagulls & Audace
     {' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );

}



const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});


export default function Login({login}) {
  useEffect(() => {
    document.title = 'Login';
  }, [])
  const [open, setOpen] = React.useState(false);
  const [reset, setReset]=React.useState(0);
  const [email, setEmail]=React.useState();
  const [loading, setLoading] = React.useState(false);

  const [password, setPassword]=React.useState();
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  }
  function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }
  const handleSubmit2 = (event) => {
    const data = new FormData(event.currentTarget);
  event.preventDefault();

  var cognitoUser = new CognitoUser({
    Username: email,
    Pool: UserPool,
  });
  cognitoUser.confirmRegistration(data.get('code'), true, function(err, result) {
    if (err) {
      alert(err.message || JSON.stringify(err));
      return;
    }
    if(result=='SUCCESS'){
      // console.log('inzio autenticazione')
      cognitoUser.authenticateUser(new AuthenticationDetails({
        Username:email,
        Password:password
      }), {
        onSuccess: function(result) {
          var accessToken = result.getAccessToken().getJwtToken();
          // console.log(accessToken)
          window.location.href = "/";
        },
      
        onFailure: function(err) {
          alert(err.message || JSON.stringify(err));
        },
      });
    }
  });
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    setLoading(true)
    const data = new FormData(event.currentTarget);
    setEmail(data.get('email'))
    setPassword(data.get('password'))
    var cognitoUser = new CognitoUser({
      Username: data.get('email'),
      Pool: UserPool,
    });


    cognitoUser.authenticateUser(new AuthenticationDetails({
      Username:data.get('email'),
      Password:data.get('password')
    }), {
      onSuccess: function(result) {
        var accessToken = result.getAccessToken().getJwtToken();
        localStorage.setItem('token',accessToken)
        window.location.href = "/";
      },
    
      onFailure: function(err) {
        setLoading(false)
        alert(err.message || JSON.stringify(err));
        if(err.message=='User is not confirmed.'){
          setOpen(true)
          cognitoUser.resendConfirmationCode(function(err, result) {
            if (err) {
              alert(err.message || JSON.stringify(err));
              return;
            }
            // console.log('call result: ' + result);
            setReset(getRandomInt(1000))
          })
        }
      },
    });
    
  }    
  

  return (
    <ThemeProvider theme={darkTheme}>
      <Container component="main" maxWidth="xs" >
        <CssBaseline />
        <Box
          sx={{
            marginTop: 15,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5">
            Login
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
            disabled={loading}
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
            disabled={loading}
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
           
            <Button
            disabled={loading}
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              { loading?<><Loader/> &nbsp;Caricamento</>:'Accedi'}
            </Button>
            <Grid container>
              <Grid item>
                <Link href="/" variant="body2">
                  {"Non hai un account? Registrati"}
                </Link>
              </Grid>
              
            </Grid>
            <Grid container>
              <Grid item>
                <Link href="/resetpass" variant="body2">
                  {"Password dimenticata?"}
                </Link>
              </Grid>
              
            </Grid>
          </Box>
        </Box>
        <Modal
        sx={{background: 'rgba(239, 239, 240, 0.5)'}}
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        
        <Box component="form" sx={{...style,alignItems:'center',backgroundColor:'black',width:'80%'}}onSubmit={handleSubmit2} >
          <Typography id="modal-modal-title" variant="h6" component="h2">
           Inserisci il codice inviato alla tua email
          </Typography>
          <TextField
              margin="normal"
              required
              fullWidth
              name="code"
              label="codice"
              id="code"
            />
            <Countdown
            key={reset}
              date={Date.now() + 60000}
              renderer={({ hours, minutes, seconds, completed })=>{
                if (completed) {
                  // Render a completed state
                  return <Button sx={{ mt: 1, mb: 2 }} variant="text" onClick={()=>{
                    var cognitoUser = new CognitoUser({
                     Username: email,
                     Pool: UserPool,
                   });
                   cognitoUser.resendConfirmationCode(function(err, result) {
                     if (err) {
                       alert(err.message || JSON.stringify(err));
                       return;
                     }
                    //  console.log('call result: ' + result);
                     setReset(getRandomInt(1000))
                   });
                 }}>Reinvia codice</Button>;
                } else {
                  // Render a countdown
                  return <span>{minutes}:{seconds}</span>;
                }
              }}
            />
          
          <Button
              type="submit"
              fullWidth
              variant="contained"
              
            >
              Conferma Account
            </Button>
        </Box>
        
      </Modal>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}