import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import Button from '@mui/material/Button';
import Countdown from 'react-countdown';
import '../App.css'
import CardMedia from '@mui/material/CardMedia';
import Card from '@mui/material/Card';
import {useQuery, useQueryClient} from 'react-query'
import { Loader } from 'rsuite';
import background1 from '../components/imgs/sfo-sea.png'
import background2 from '../components/imgs/sfondo.png'
import background3 from '../components/imgs/sfo-audace.png'
import { useEffect } from 'react';
export default function Pagequiz({userid,update}) {
  useEffect(() => {
    document.title = 'Quiz';
  }, [])
  var midnight = new Date();
  midnight.setHours( 24 );
  midnight.setMinutes( 0 );
  midnight.setSeconds( 0 );
  midnight.setMilliseconds( 0 );
  localStorage.setItem('page',1)

function timeout(delay) {
  return new Promise( res => setTimeout(res, delay) );
}

async function correct(){
  
  setV(false)
  await timeout(2000)
  setValue('')
  setV(true)
  queryClient.invalidateQueries(['quizs'])
}
const [helperText, setHelperText] = React.useState('');

const queryClient=useQueryClient()
const [counter, setCounter] = React.useState(0);
const {data: quizs, isLoading}=useQuery({
  queryFn: async ()=>{
    const dati={
      id: userid,
      data:new Intl.DateTimeFormat('it',{
        dateStyle: 'short',
       }).format(),
    }
    const options={
      method:'POST',
      headers:{
        'Content-Type':'application/json'
      },
      body:JSON.stringify(dati)
    }
   var response= await fetch("https://z7b1yprr7b.execute-api.eu-south-1.amazonaws.com/users",options)
   var res = await response.json()
  //  console.log(res)
   if(res.length>0)setHelperText('Questa domanda vale'+ ' '+res[counter].valore+' '+ 'punti')
   
   return res
  },
  queryKey:['quizs']
})
    const [value, setValue] = React.useState('');
   
    const [error, setError] = React.useState(false);
    
    const [sucess,setSc]=React.useState(false);
    const [visualize, setV]=React.useState(true);

    const handleRadioChange = (event) => {
      setValue(event.target.value);
      setHelperText(' ');
      setError(false);
    };

 
    const handleSubmit = async (event) => {
      event.preventDefault();
      if (value){
        const dati={
          risposta: value,
          id:quizs[counter].id,
          valid:quizs[counter].valid.toString(),
          userid:userid.toString()
        }
        // console.log(dati)
        const options={
          method:'POST',
          headers:{
            'Content-Type':'application/json'
          },
          body:JSON.stringify(dati)
        }
        var res=await fetch("https://z7b1yprr7b.execute-api.eu-south-1.amazonaws.com/verifica",options)
        var data= await res.json()
        // console.log(data)
        if (data.status=='ok'){
          update()
          setSc(true)
        }
        else{
          update()
          setSc(false)
        } 
      correct()
      } else {
        setHelperText('Seleziona una risposta');
        setError(true);
      }
    };


    return (
      <div  >
      {isLoading?  <Loader size="md" center vertical content="caricamento" /> :quizs.length>0?<div className='pagequiz' style={{backgroundSize: 'cover',backgroundRepeat: 'no-repeat',backgroundImage:`url(${quizs[counter].img=="seagulls"?background1:background3})`,backgroundPosition:'center', width:"100%",height:'100%'}}>
      
      <form onSubmit={handleSubmit} >
      <CardMedia
      sx={visualize ?{display:'none'}:{ minWidth: 375}}
     component="img"
     width='240'
     height="280"
     image={sucess? require('../components/imgs/sucess.gif'):require('../components/imgs/error.gif')}
   />
 <Card sx={!visualize ?{display:'none'}:{ textAlign:'center', marginTop:'30px'}} >
 
        <div style={{ fontSize:20,margin:' 0 auto',justifyContent:'center',textAlign: 'center',alignItems:'center',display:"flex",width:"100%", height:'100%'}}> 🕒&nbsp;<Countdown 
            // key={reset}
              date={midnight.getTime() }
              renderer={({ hours, minutes, seconds, completed })=>{
                if (completed) {
                  // Render a completed state
                  return "ok"
                } else {
                  // Render a countdown
                  return <span>{hours}:{minutes}:{seconds}</span>;
                }
              }}
            /></div>
  
 <FormControl sx={!visualize ?{display:'none',m:2}:{m:3}} error={error} variant="standard">
 <div className='formquiz'>
   <FormLabel sx={{  fontWeight: "bold"}} id="demo-error-radios">{quizs[counter].domanda.toUpperCase()}</FormLabel>
   </div>
   <div className='quizs' style={{padding:3}}>
   <RadioGroup
   
     aria-labelledby="1"
     name="quiz"
     value={value}
     onChange={handleRadioChange}
   >
     <FormControlLabel value={quizs[counter].risposte[0].ris} control={<Radio />} label={quizs[counter].risposte[0].ris} />
     <FormControlLabel value={quizs[counter].risposte[2].ris} control={<Radio />} label={quizs[counter].risposte[2].ris}/>
   </RadioGroup>

   <RadioGroup
   
     aria-labelledby="2"
     name="quiz"
     value={value}
     onChange={handleRadioChange}
   >
     <FormControlLabel value={quizs[counter].risposte[1].ris} control={<Radio />} label={quizs[counter].risposte[1].ris} />
     <FormControlLabel value={quizs[counter].risposte[3].ris} control={<Radio />} label={quizs[counter].risposte[3].ris} />
     
   </RadioGroup>
   </div>
   <FormHelperText sx={{width:"100%",textAlign:"center"}}>{helperText}</FormHelperText>
   <Button sx={{ mt: 1, mr: 1 }} type="submit" variant="outlined" >
     Rispondi
   </Button>
 </FormControl>


</Card>  
</form>
    </div> :<div ><Card className='pagequiz' style={{backgroundSize: 'cover',backgroundRepeat: 'no-repeat',backgroundImage:`url(${background2})`,backgroundPosition:'center', width:"100%",height:'100%'}}>
    ⚠️ NESSUN QUIZ DISPONIBILE ⚠️
    </Card>  </div> }
    </div>  
    );
  }
