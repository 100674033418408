import './App.css'
import Quiz from './components/quizcard'
import {useQuery} from 'react-query'
import background from './components/imgs/sfondo.png'
import CardMedia from '@mui/material/CardMedia';
import { Loader } from 'rsuite';
import { useEffect,useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { Divider } from 'rsuite';

import Button from '@mui/material/Button';

function App({userid}) {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
 
    boxShadow: 24,
    p: 1,
  }

  const [cookie,setCookie]=useState(false)
  const handleClose = () => setCookie(false);
  useEffect(() => {
    if(localStorage.getItem("cookie")==null){
      localStorage.setItem("cookie",false)
      setCookie(false)
    }else if(localStorage.getItem("cookie")=="true")setCookie(true)
    else if(localStorage.getItem("cookie")=="false")setCookie(false)
  }, [cookie])

  useEffect(() => {
    document.title = 'Home';
  }, [])
  localStorage.setItem('page',0)
  var content=true
    const {data: posts, isLoading}=useQuery({
        queryFn: async ()=>{

         var response= await fetch("https://z7b1yprr7b.execute-api.eu-south-1.amazonaws.com/posts")
         return await response.json()
         
        },
        queryKey:['posts']
      })


    
      function display(){
        var postsdata=posts
        posts.sort(function(a,b) {
          a = a.data.split('/').reverse().join('');
          b = b.data.split('/').reverse().join('');
          
         return a > b ? 1 : a < b ? -1 : 0;
          // return a.localeCompare(b);         // <-- alternative 
          //[1,3,2] [1,2,3]  i=1     
        });
        for(var i=0;i<postsdata.length-1;i++){
          if(postsdata[i].data==posts[i+1].data){
            if(new Date('1970/01/01 ' + posts[i].ora) > new Date('1970/01/01 ' + postsdata[i+1].ora)){
              var el1=posts[i]
              var el2=posts[i+1]
              posts[i]=el2
              posts[i+1]=el1
            }
          } 
        }

        return posts.map((post,index)=>{
          const f= new Intl.DateTimeFormat('en',{
           
           
          })
          var today = new Date();
         var dd = String(today.getDate()).padStart(2, '0');
         var mm = String(today.getMonth() + 1).padStart(2, '0');
         var yyyy = (today.getFullYear());
         today = dd + '/' + mm + '/' + yyyy;
         function stringToDate(str) {
           const [dd, mm, yyyy] = str.split('/');
           return new Date(yyyy, mm - 1, dd);
         }
        //  console.log(new Intl.DateTimeFormat('it',{
        //   dateStyle: 'short',
        //  }).format())
       //    disabled={!(f.format()==post.data)}
          if(stringToDate(today)<=stringToDate(post.data)) return <Quiz key={index}titolo={post.titolo} data={new Intl.DateTimeFormat('it',{
            weekday:'short',
            day:'numeric',
            month:'numeric'
            
           }).format(stringToDate(post.data))} ora={post.ora} squadra2={post.squadra2} squadra1={post.squadra1} qr={userid} img={require(post.img=='audace'?'./components/imgs/audace.png':'./components/imgs/sea-gulls.png') }disabled={!(new Intl.DateTimeFormat('en-GB', {}).format()==post.data)} color={post.img=='audace'?'white':'black'}content={post.comunicazione?post.comunicazione:true}/> 
       })
      }
      // !(new Intl.DateTimeFormat('en-GB', {}).format()==post.data)
      
    return (
        <div style={{backgroundSize: 'cover',backgroundRepeat: 'no-repeat',backgroundImage:`url(${background})`,width:'100%',height:'100%'}}><div className='cardmedia'><CardMedia
        sx={{position:'fixed',zIndex:50,backgroundColor:'#0b75cc',minWidth:140,maxWidth:540,alignSelf:'center'}}
        component="img"
        
        image={require('./components/imgs/testata.png')}
      /></div>
            
            
                {isLoading? <Loader size="md" center vertical content="caricamento" /> :<div className='quiz' style={{paddingBottom: '60px' }}>{display()}
                <Modal
        sx={{ background: 'rgba(239, 239, 240, 0.5)'}}
        open={cookie}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{...style,backgroundColor:'white', overflowY:'auto',color:'black',width:'95%',maxHeight:"105%"}}>
        <Divider><Typography id="modal-modal-title" variant="h6" component="h2">
      Cookie
    </Typography></Divider>
   
    Questo sito installa cookie e altri strumenti di tracciamento:
<br></br>• tecnici necessari per il funzionamento del sito;
<br></br>• profilazione, necessari per offrirti servizi in linea con i tuoi interessi.
<br></br><br></br>Cliccando “Accetta tutto” acconsenti all'utilizzo di tutti i cookie. <br></br><br></br>Cliccando "Accetta Essenziali" verranno installati solo i cookie tecnici.
<br></br><br></br>Per saperne di più consulta la nostra<Link color="inherit" href="/privacy"> Informativa Sulla Privacy</Link>.
        <Button
               onClick={()=>{
                setCookie(false)
                localStorage.setItem('cookie',false)
              }}
              fullWidth
              variant="contained"
              sx={{ mt: 1, mb: 1 }}
            >
              Accetta Essenziali
            </Button>
            <Button
              onClick={()=>{
                setCookie(false)
                localStorage.setItem('cookie',false)
              }}
              fullWidth
              variant="contained"
              sx={{ mt: 1, mb: 1 }}
            >
              Accetta Tutto
            </Button>
        </Box>
      </Modal> </div> }
             
               
            </div>
           
    );
  }
  
  export default App;
  