import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { Button, CardActionArea} from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import QrCodeIcon from '@mui/icons-material/QrCode';
import QRCode from 'react-qr-code';
import {Badge} from 'rsuite';
function Quiz({ora,titolo,squadra2,squadra1,data,img,disabled,qr,content,color}) {
  var st={}
  if (disabled){
    st={
      grayscale:100,
      bt:true
    }
  }else{
    st={
      grayscale:0,
      bt:false
    }
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
 
    boxShadow: 24,
    p: 4,
  }
   
  const [open, setOpen] = useState(false);
 
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
    <div style={{position:'relative'}}>
   <Typography  variant="h6" sx={{zIndex:1,transform:'translate(-15%,330%)',position:'absolute',color:color}}>{data.toUpperCase()} </Typography>
   <Typography  variant="h6" sx={{zIndex:1,transform:'translate(-25%,395%)',position:'absolute',color:color}}>{ora} </Typography>
    <CardMedia
    sx={{filter:"grayscale("+st.grayscale+ "%)",transform:'translate(1.7%,19%)',width:'122%',maxWidth:'200%',height:'auto',m:-5,position:'relative',minWidth:100,alignSelf:'center'}}
          component="img"
          
          image={img}
        />
        </div>
  <Card sx={{ backgroundColor:'#103051',width: 360,maxWidth: 460, minWidth:360,filter:"grayscale("+st.grayscale+ "%)" ,borderRadius: '0 0 25px 0;'}}>
  
 
      <CardActionArea>
      
       
       
        <CardContent>
        <Badge style={{display:content!=true?'':'none'}} content={content}></Badge>

          <Typography gutterBottom variant="h5" component="h5">
            {squadra1}<br/>{squadra2}

          </Typography>
          
          <Typography variant="body2" color="text.secondary" >
             <Button disabled={st.bt} onClick={handleOpen}style={{    
           position:'absolute',
           transform:"translate(420%,-115%)"}}><QrCodeIcon sx={{fontSize:40}}/></Button>
          </Typography>
        </CardContent>
       
      </CardActionArea>
     
    </Card>
    
    <Modal
        sx={{ background: 'rgba(239, 239, 240, 0.5)'}}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{...style,backgroundColor:'white', color:'black',width:'90%',height:"50%"}}>
          
         <QRCode  size={256}
                  style={{ ...style,height: "auto", maxWidth: "90%",maxHeight: "80%", width: "80%" }}
                  viewBox={`0 0 256 256`}
                  value={qr}/>
        </Box>
      </Modal>
      
    </>
  );
}

export default Quiz;
