import * as React from 'react';


export default function Privacy() {

      
return(
    <div style={{textAlign:'justify',fontSize:"20px", margin:"10px", wordSpacing:'2px'}}>
  <div style={{textAlign:'center'}}> INFORMATIVA SUL TRATTAMENTO DEI DATI PERSONALI
PRIVACY POLICY<br></br><br></br></div>

A.S.D. MONOPOLI SEAGULLS (di seguito Seagulls o Titolare) rilascia all’Interessato e cioè alla persona alla quale i dati personali si riferiscono, le seguenti informazioni ai sensi dell’art. 13
Regolamento UE n. 2016/679 del 27 aprile 2016 (di seguito GDPR), relativo alla protezione
delle persone fisiche con riguardo al trattamento dei dati personali. La presente informativa
ha lo scopo di descrivere le caratteristiche dei trattamenti svolti in relazione ai dati personali
forniti dall’Interessato e le misure adottate al fine di tutelarne i diritti.
La presente informativa viene rilasciata nel sito di Seagulls (www.tifaevinci.it, di seguito il
Sito), nella sezione dedicata alla policy sul trattamento dei dati personali nella home-page
del Sito.
<br></br><br></br>
Questo sito installa cookie:
<br></br>• tecnici necessari per il funzionamento del sito;
<br></br><br></br>
Titolare del trattamento<br></br>
Titolare del trattamento è Seagulls con sede legale in Monopoli in via Giovanni Barnaba, n.
11, CAP 70043 – (P.IVA 08334040725).
<br></br>Titolare: monopoliseagulls@gmail.com
<br></br><br></br>
Dati oggetto di trattamento<br></br>
Il Titolare raccoglie essenzialmente i seguenti dati personali:
<br></br>- dati identificativi dell’Utente, quali, ad esempio, gli indirizzi IP e i nomi a dominio del
computer
<br></br>- Dati relativi all’utilizzo del Sito da parte dell’Utente
<br></br>- Dati forniti volontariamente dall’Utente (informazioni identificative) per l’accesso a
determinati servizi offerti tramite il Sito, per i quali si rinvia alle apposite informative
presenti nelle relative sezioni di riferimento
<br></br>- Cookies. Questi ultimi sono costituiti da file di testo che si generano automaticamente
nell’elaboratore elettronico dell’Utente in seguito alla visita di alcune pagine del Sito.
Taluni di questi file (cookies di sessione) vengono rimossi in modo automatico alla
chiusura del browser. Un’altra tipologia di cookies, invece, viene registrata e
memorizzata sul computer dell’Utente (ad esempio per rendere automatica la
procedura di accesso all’area riservata, l’Utente potrà scegliere che i suoi dati
identificativi di User-id e password siano memorizzati in uno di questi file).
Per maggiori informazioni relative ai cookie, al loro utilizzo e come disabilitarli si rinvia
all’informativa cookie.
<br></br><br></br>
Finalità del trattamento e tempo di conservazione
I dati personali verranno trattati per le seguenti Finalità:
<br></br>a) gestire la corretta fruizione del Sito.
<br></br>b) Valutare, in forma statistica, l’utilizzo del Sito da parte degli utenti.
<br></br>c) Marketing sia diretto che profilato a mezzo di cookie di terze parti.
<br></br>d) Accertare eventuali illeciti compiuti dagli utenti a danno di terzi o a danno del Titolare.
<br></br>e) Adempiere alla normativa europea e nazionale, nonché ai provvedimenti dell’Autorità
Garante.
I dati trattati per le suddette Finalità verranno conservati come meglio precisato nelle
relative informative.
Liceità del trattamento
Il trattamento è lecito perché fondato sulle seguenti basi giuridiche:
- interesse legittimo del Titolare (gestire la corretta fruizione del sito web ed accertare
eventuali illeciti compiuti dagli utenti a danno di terzi o a danno del Titolare).
- Consenso dell’Interessato espresso durante l’esperienza di navigazione (uso dei cookie).
<br></br><br></br>
Modalità del trattamento
I dati personali sono trattati per il perseguimento delle Finalità indicate nella presente
informativa su supporto elettronico.
Natura obbligatoria o facoltativa del conferimento dei dati
Qualora l’Utente preferisca non ricevere cookie, può impedirne la trasmissione da parte del
sito web mediante opportuna configurazione del proprio browser di navigazione Internet.
In alcuni casi, però, la fruizione di alcune parti del Sito potrebbe essere condizionata alla
memorizzazione dei cookie sul computer dell’Utente.
Categorie di soggetti ai quali i dati personali possono essere comunicati
Il trattamento dei dati personali fornitici potrà essere effettuato, nel rispetto del principio
della stretta indispensabilità dei trattamenti:
<br></br>
- dal personale dipendente del Titolare, che agisce e tratta i dati sotto l’autorità e le
istruzioni dello stesso, a norma dell’art. 29 del GDPR ovvero del personale dipendente
designato dal Titolare, a norma dell’art. 2 quaterdecies del D. Lgs. n. 101/2018.
<br></br>
- Da persone fisiche o giuridiche la cui facoltà di accedere ai dati personali dell’Interessato
sia riconosciuta da disposizioni di legge previste dal diritto dell’Unione Europea o dalla
legge italiana, quali, a titolo esemplificativo, autorità competenti e/o organi di vigilanza
per l’espletamento degli obblighi di legge, e pubbliche Amministrazioni per i loro fini
istituzionali.
<br></br>
- Da persone fisiche o giuridiche di cui il Titolare si avvale per lo svolgimento delle
attività strumentali al raggiungimento delle Finalità (come ad esempio, fornitori di
software, partner cloud, datacenter, consulenti IT). I soggetti terzi che accederanno ai
dati, lo faranno rispettando la normativa vigente in materia di protezione dei dati
personali e le istruzioni impartite dal Titolare e saranno in ogni caso nominati dal
<br></br><br></br>
Titolare, Responsabili esterni del trattamento.
Un elenco dei Responsabili del trattamento è disponibile presso la sede del Titolare.
Diffusione dei dati
I dati non verranno diffusi in alcun caso.
Diritti dell’Interessato
L’Interessato può esercitare nei confronti del Titolare i seguenti diritti:
<br></br>
- Diritto di accesso: ha il diritto di ottenere una copia dei dati personali di cui siamo in
possesso e che sono sottoposti a trattamento.
- Diritto alla rettificazione: ha il diritto di rettificare i dati personali conservati dal
Titolare qualora non siano aggiornati o corretti.
- Diritto di opporsi al trattamento di dati personali per Finalità commerciali: può
chiedere che il Titolare cessi di inviare comunicazioni commerciali in qualsiasi
momento.
<br></br>
- Diritto di opporsi a decisioni basate su processi esclusivamente automatizzati: può
chiedere di non essere destinatario di decisioni prese in base a processi esclusivamente
automatizzati, inclusa l’attività di profilazione.
<br></br>
- Diritto di revocare un consenso prestato: ha il diritto di revocare il consenso prestato
per un determinato trattamento in qualsiasi momento.
<br></br>
- Diritto di rivolgersi all’Autorità Garante per la protezione dei dati personali: ha il
diritto di rivolgersi all’Autorità̀ Garante per la protezione dei dati personali qualora
abbia dubbi sul trattamento di dati personali operato dal Titolare.
L’Interessato può esercitare anche i seguenti diritti al ricorrere di determinate circostanze:
<br></br>
- Diritto alla cancellazione: può richiedere che il Titolare cancelli i dati personali
dell’Interessato qualora siano cessati gli scopi del trattamento e non sussistano interessi
legittimi o norme di legge che ne impongano la continuazione.
<br></br>
- Diritto di opporsi ad un trattamento: può chiedere che il Titolare cessi di svolgere un
determinato trattamento sui dati personali dell’Interessato.
<br></br>
- Diritto di limitare il trattamento: ha il diritto di richiedere che il Titolare limiti le
operazioni di trattamento sui dati personal dell’Interessato.
<br></br>
- Diritto alla portabilità del dato: ha il diritto di ottenere una copia dei dati in un formato
strutturato di uso comune e leggibile da dispositivo automatico da un altro Titolare.
Per esercitare ciascuno dei citati diritti, l’Interessato può inviare una e-mail o scrivere al
seguente indirizzo specificando la richiesta e fornendo al Titolare le informazioni necessarie
alla corretta identificazione del mittente (allegando anche una copia del documento di
identità) ai seguenti recapiti:
<br></br>- via posta: ASD Monopoli Seagulls - via Giovanni Barnaba, n. 11, Monopoli(BA) CAP 70043 
<br></br>- via e-mail: monopoliseagulls@gmail.com
<br></br><br></br>
Seagulls risponderà entro un mese. Se per qualche motivo il Titolare non riuscisse a
rispondere, fornirà una spiegazione dettagliata sul motivo per cui non può soddisfare la
richiesta.<br></br>
La presente informativa potrebbe subire nel tempo delle modifiche o delle integrazioni,
pertanto La invitiamo a consultare questa pagina - per rimanere costantemente aggiornato
       
    </div>
  );
}


