import * as React from 'react';
import './App.css'
import Navig from './components/nav.js'
import Classif from './classifica.js'
import Home from './home.js'
import {Route, Routes,Navigate,} from 'react-router-dom'
import Pagequiz from './pages/pagquiz.js';
import Account from './pages/account.js'
import Accesso from './pages/accesso.js';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Registrazione from './pages/registrazione.js'
import { QueryClient,QueryClientProvider} from 'react-query';
import Login from './pages/login.js';
import UserPool from "./components/userPool.js"
import Reset from './pages/resetpass.js';
import Privacy from './pages/privacy.js'
const queryClient=new QueryClient()

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

function App() {

  const [email, setEmail] = React.useState(true);
  const [userid, setId] = React.useState();
  const [login, setLogin] = React.useState();
  async function updateAccountInfo(){
    
    var cognitoUser = UserPool.getCurrentUser();

    if (cognitoUser != null) {
      cognitoUser.getSession(function(err, session) {
        if (err) {
          alert(err.message || JSON.stringify(err));
          return;
        }
        // console.log('session validity: ' + session.isValid());
    
        cognitoUser.getUserAttributes(async function(err, attributes) {
          if (err) {
            // console.log(err)
          } else {
            setLogin(true)
            // console.log(attributes)
            setId(attributes[4].Value)
            localStorage.setItem("id",attributes[4].Value);
            const dati={
              nome:attributes[3].Value,
              cognome:attributes[2].Value,
              userid:attributes[4].Value,
            }
            // console.log(dati)
            const options={
              method:'POST',
              headers:{
                'Content-Type':'application/json'
              },
              body:JSON.stringify(dati)
            }
            const accountdetails = await fetch('https://z7b1yprr7b.execute-api.eu-south-1.amazonaws.com/newuser',options)
            const details =await accountdetails.json()
            // console.log(details)
            localStorage.setItem("username", details.username)
            localStorage.setItem("fanpunti", details.fanpunti)
            localStorage.setItem("tickets", details.ticket)
          }
        });
    
      });
  
    }
   
  
  }

  React.useEffect(()=>{

      // var access_token = new URLSearchParams(window.location.hash).get('access_token');
      //   console.log(access_token)
      //   const res = await fetch('https://tifaevinci.auth.eu-south-1.amazoncognito.com/oauth2/userInfo', {
      //       method: 'GET',
      //       headers: {
      //           'Content-type': 'application/json',
      //           'Authorization': `Bearer ${access_token}`, // notice the Bearer before your token
      //       }
      //   })
      //   var ris=await res.json()
      //   console.log(ris)
      //   setEmail(ris.email)
      //   setId(ris.sub)
      //   if(ris.sub)localStorage.setItem("id", ris.sub);
        
          updateAccountInfo()
        
        
       
    
  },[])
  return (
<>
<QueryClientProvider client={queryClient}>
<ThemeProvider theme={darkTheme}>
      <CssBaseline />
     { login?<div> 
      <Routes>
        <Route path="/" element={<Home email={setEmail} userid={userid}/> }replace />
        <Route path="/classifica" element={<Classif/>} replace/>
        <Route path="/quiz" element={<Pagequiz update={updateAccountInfo} userid={userid} replace/>}/>
        <Route path="/account" element={<Account email={email} userid={userid} replace/>}/>
        <Route path="/privacy" element={<Privacy/>}/>

      </Routes>
      <div className="BottomNavigation">
        <Navig/>
      </div>

      </div>:
            <Routes>
            <Route path="/" element={<Registrazione login={updateAccountInfo}/>} />
            <Route path="/accedi" element={<Login/>}login={updateAccountInfo}/>
            <Route path="/resetpass" element={<Reset/>}/>
            <Route path="/privacy" element={<Privacy/>}/>
            <Route
              path="*"
              element={<Navigate to="/" replace />}
            />
          </Routes>}
   
    </ThemeProvider>
    </QueryClientProvider>
    </>
  );
}

export default App;
