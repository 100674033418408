import * as React from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { useNavigate} from 'react-router-dom'
import HomeIcon from '@mui/icons-material/Home';
import StarIcon from '@mui/icons-material/Star';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';


export default function SimpleBottomNavigation() {
  const [value, setValue] = React.useState(0);
  React.useEffect(()=>{
 if(localStorage.getItem('page'))setValue(Number(localStorage.getItem('page')))
},[])
  const navigate=useNavigate()
  return (
    <Box sx={{zIndex:2, bottom: 0,position: 'fixed', width:'100%',marginBottom: '0px'}}>
      <BottomNavigation
        showLabels
        value={Number(localStorage.getItem('page'))}
        onChange={(event, newValue) => {
          
          localStorage.setItem('page',newValue)
          
          if(newValue===0) navigate('/')
          else if(newValue===1) navigate('/quiz')
          else if(newValue===2) navigate('/classifica')
          else if(newValue===3) navigate('/account')
        }}
      >
        <BottomNavigationAction label="Home" icon={<HomeIcon />} />
        <BottomNavigationAction label="Quiz" icon={<LiveHelpIcon/>} />
        <BottomNavigationAction label="Classifica" icon={<StarIcon/>} />
        <BottomNavigationAction label="Account" icon={<AccountCircleIcon />} /> 
      </BottomNavigation>
    </Box>
  );
}