
import { IconButton } from '@mui/material';
import Sheet from 'react-modal-sheet';
import InfoIcon from '@mui/icons-material/Info';
import React, { useState, useEffect } from 'react';
import { Divider } from 'rsuite';

export default function Informativa(){
    const [open1, setOpen1] = React.useState(false);

    return(
        <>
        <IconButton sx={{bottom:0, transform: 'translate(0%, -5%)'}} variant="text" onClick={() => setOpen1(true)}><InfoIcon/></IconButton>
        <Sheet isOpen={open1} onClose={() => setOpen1(false)} >
        <Sheet.Container >
          <Sheet.Header />
          <Sheet.Scroller >
      <div style={{fontSize:20,color:'black',textAlign:"justify",margin:"10px"}}>
      
      <div style={{fontSize:20,color:'red',textAlign:"center"}}>CONCORSO A PREMI "TIFA E VINCI"</div><br></br>
      
      <div style={{fontSize:20,color:'black',textAlign:"center"}}>"TIFA E VINCI", un concorso dedicato ai fan che offre l'opportunità di vincere un fantastico Piaggio Liberty S 125cc.
      Il concorso si basa su un sistema di punti, chiamati "fanpunti," che possono essere guadagnati attraverso varie attività, e serviranno a generare i “fanticket”, ovvero i biglietti della lotteria, virtuali, utilizzati nell’estrazione finale.</div>
      <br></br>
      <br></br>
      <Divider><div style={{fontSize:20,color:'black',fontWeight:'bold'}}>Articolo 1 – Organizzatori</div></Divider>
      Il concorso a premi " TIFA E VINCI " è organizzato congiuntamente dalle Società sportive “Audace Monopoli” e “Seagulls Monopoli”.
      <br></br>
      <br></br>
      <Divider><div style={{fontSize:20,color:'black',fontWeight:'bold'}}>Articolo 2 - Durata del concorso</div></Divider>
      Il concorso avrà inizio il 13 gennaio 2024 e terminerà il 13 aprile 2024, per un totale di 11 gare da disputarsi in tale periodo di tempo (6 Audace Monopoli, 5 Seagulls Monopoli) e 13 quiz settimanali (inizio mercoledì 17 gennaio 2024, termine mercoledì 10 aprile 2024). Date ed orari dei match sono visibili sulla webapp “TIFA E VINCI” e sono soggetti a possibili variazioni.
      <br></br>
      <br></br>
      <Divider><div style={{fontSize:20,color:'black',fontWeight:'bold'}}>Articolo 3 - Destinatari</div></Divider>
      Il concorso è aperto a tutte le persone residenti in Italia che soddisfano i requisiti di partecipazione stabiliti nel presente regolamento.
      <br></br>
      <br></br>
      <div style={{fontSize:20,color:'black',fontWeight:'bold'}}>Articolo 4 - Modalità di partecipazione</div><br></br>
      Per partecipare al concorso, i partecipanti devono iscriversi alla web app “TIFA E VINCI” ed accettarne i termini di utilizzo, nonché autenticarsi ad essa.
      <br></br>
      <br></br>
      <div style={{fontSize:20,color:'black',fontWeight:'bold',textAlign:'center'}}>Articolo 5 – Obiettivo: accumulare fanpunti per guadagnare fantickets</div><br></br>
      L’ obiettivo del concorso è guadagnare fantickets, i biglietti virtuali estratti per decretare il vincitore. 
      
          Ogni 20 fanpunti accumulati, verrà accreditato 1 fanticket.
      
      
      Puoi guadagnare "fanpunti" seguendo queste attività:
      
      <br></br><br></br> • Match Casalinghi:
      
      Al tuo arrivo alla Tensostruttura di via Amleto Pesce per assistere ai match casalinghi,  mostra il tuo QR code personale presente nella sezione ACCOUNT della webapp “TIFA E VINCI” con il tuo smartphone. Ogni scansione ti permetterà di autenticarti nel sistema e di guadagnare 15 "fanpunti" per ogni gara a cui assisti.
      
      <br></br><br></br>  • Quiz settimanali su Tifa E Vinci:
      
      Ogni Mercoledì, partecipa al quiz su Tifa E Vinci organizzato da Audace & Seagulls.
      
      Ogni risposta corretta ti farà guadagnare 5 "fanpunti."
      
      Ogni risposta errata ti farà guadagnare 2 "fanpunti."
      
      <br></br><br></br>    • Segui le Pagine Social:
      
      Segui le pagine social ufficiali di Audace & Seagulls.
      
      Durante la stagione, verranno comunicate modalità di assegnazioni extra di ben 400 fanpunti da entrambe le squadre.
      
      Saranno previsti bonus e punti doppi per alcuni match, che verranno comunicati anch’essi tramite i canali social.
      <br></br>
      <br></br>
      <Divider><div style={{fontSize:20,color:'black',fontWeight:'bold'}}>Articolo 6 - Premio</div></Divider>
      Il premio in palio è uno scooter Piaggio Liberty S 125cc.
      <br></br>
      <br></br>
      <div style={{fontSize:20,color:'black',fontWeight:'bold',textAlign:'center'}}>Articolo 7 - Estrazione e comunicazione del vincitore</div><br></br>
      L'estrazione del vincitore avverrà in presenza presso la Tensostruttura di via A. Pesce in occasione di una gara di Playoff/Playout di una delle società organizzatrici, previo comunicazione tramite social dell’evento. Qualora nessuna delle due società organizzatrici raggiunga il traguardo di Playoff/Playout, l’estrazione avverrà attraverso una diretta social congiunta, che si effettuerà in data preventivamente comunicata sulle pagine social ufficiali. Sia in presenza, sia attraverso estrazione in diretta social, Il vincitore, qualora non dovesse rivendicare la vincita, sarà contattato tramite il recapito email indicato in fase di registrazione al concorso per la durata di giorni 60 a partire dalla data di estrazione. Superati i 60 giorni solari, qualora il vincitore non rivendichi il premio e/o i dati forniti fossero errati, rendendo impossibile l’ assegnazione, il premio verrà aggiudicato nuovamente attraverso nuova estrazione.
      <br></br>
      <br></br>
      <Divider><div style={{fontSize:20,color:'black',fontWeight:'bold'}}>Articolo 8 - Obblighi del vincitore</div></Divider>
      Il vincitore dovrà rendersi disponibile entro 90 giorni per fornire documenti di identità e ritirare il premio presso la concessionaria avente in carico la compravendita del veicolo. Le tasse di immatricolazione dello scooter sono a carico del vincitore.
      <br></br>
      <br></br>
      <div style={{fontSize:20,color:'black',fontWeight:'bold',textAlign:"center"}}>Articolo 9 - Privacy e trattamento dei dati personali</div><br></br>
      I dati personali forniti dai partecipanti saranno trattati conformemente alla normativa sulla privacy vigente.
      <br></br>
      <br></br>
      <Divider><div style={{fontSize:20,color:'black',fontWeight:'bold'}}>Articolo 10 - Disposizioni finali</div></Divider>
      I partecipanti accettano tutte le disposizioni del presente regolamento. Gli organizzatori si riservano il diritto di apportare modifiche al regolamento, se necessario, previa comunicazione agli interessati.</div>
             </Sheet.Scroller>
        </Sheet.Container>
        <Sheet.Backdrop />
      </Sheet></>
    );
}