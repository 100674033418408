import * as React from 'react';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import UserPool from "../components/userPool.js"
import { useEffect } from 'react';
import Modal from '@mui/material/Modal';
import {CognitoUserAttribute, CognitoUser,AuthenticationDetails} from "amazon-cognito-identity-js"
import { Loader } from 'rsuite';
import Typography from '@mui/material/Typography';
import FormHelperText from '@mui/material/FormHelperText';

export default function Reset() {
    var cognitoUser
    const [conf, setConf]=React.useState(0);
    const [open, setOpen]=React.useState(false);
    const [email, setEmail]=React.useState();

    const [password, setPassword]=React.useState();
    const [loading, setLoading] = React.useState(false);
    useEffect(() => {
        document.title = 'Reset Password';
      }, [])
  function handleSubmit(event,email){
    event.preventDefault()
    
    const data = new FormData(event.currentTarget);
    setLoading(true)
    setPassword(data.get('new'))
    console.log(loading)
 
        if(data.get('conf')==data.get('new')){
            console.log(email)
            new CognitoUser({
                Username: email,
                Pool: UserPool,
              }).confirmPassword(data.get('code'), data.get('new'), {
                onSuccess() {
                    alert("Password modificata con sucesso")
                    window.location.href = "/accedi";
                },
                onFailure(err) {
                    alert("Qualcosa e' andato storto riprova")
                    window.location.href = "/accedi";
                },
            });
        }else console.log('no')

    
    
      
        
      }   

      async function send(event){
        event.preventDefault()
        const data = new FormData(event.currentTarget);
        if(data.get('email')){
        setLoading(true)
        setEmail(data.get('email'))
        cognitoUser = new CognitoUser({
            Username: data.get('email'),
            Pool: UserPool,
          });
        cognitoUser.forgotPassword({
            onSuccess: function(data) {
                // successfully initiated reset password request
                console.log('CodeDeliveryData from forgotPassword: ' + data);
                setLoading(false)
                setOpen(true)
            },
            onFailure: function(err) {
                alert(err.message || JSON.stringify(err));
            }
        });
    }else {
        alert('compila il campo email')
        setLoading(false)
    }
      }
      
return(
    <>
   
        <Container component="main" maxWidth="xs" >
        {open?'': <>
        <div style={{
            marginTop: 15,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
    <Typography component="h1" variant="h5">
            Cambia Password
          </Typography></div>
        <Box component="form" onSubmit={send} noValidate >
            <TextField
            // disabled={loading}
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                autoFocus
            />
               <FormHelperText sx={{width:"100%",}}>Inserisci la mail dell'account al quale vuoi resettare la password</FormHelperText>

            <Button
              disabled={loading}
              type='submit'
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            > { loading?<><Loader/> &nbsp;Caricamento</>:'Invia Codice'}
            </Button></Box></>}
        <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
         <Container component="main" maxWidth="xs" >
        <div style={{
            marginTop: 15,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}> <Typography component="h1" variant="h5">
            Cambia Password
          </Typography></div>
        <Box component="form" onSubmit={(event)=>handleSubmit(event,email)} noValidate >

            <TextField
            // disabled={loading}
                margin="normal"
                required
                fullWidth
                id="code"
                label="Codice"
                name="code"
                autoComplete="code"
                autoFocus
            />
            <FormHelperText sx={{width:"100%",}}>Inserisci il codice inviato via mail</FormHelperText>

            <TextField
            // disabled={loading}
                margin="normal"
                required
                fullWidth
                id="new"
                label="Nuova Password"
                type="password"
                name="new"
                autoComplete="new"
                autoFocus
            />
             <TextField
            // disabled={loading}
                margin="normal"
                required
                fullWidth
                id="conf"
                label="Conferma Password"
                type="password"
                name="conf"
                autoComplete="conf"
                autoFocus
            />
              <Button
              disabled={loading}
              type='submit'
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            > { loading?<><Loader/> &nbsp;Caricamento</>:'Cambia Password'}
            </Button>
            </Box>
            </Container>
            </Modal>
            </Container>
      
    </>
  );
}


